<template>
  <!-- <div class="sponsors sponsors-mobile">
    <div class="sponsors-container">
      <div class="tips">{{$t('sponsor.tips')}}</div>
      <setion class="sponsors-item sponsors-item-mobile">
        <aside class="sponsors-txt sponsors-txt-mobile">{{$t('sponsor.title1')}}</aside>
        <div class="sponsors-box sponsors-box-large">
          <div class="sponsors-item sponsors-item-mobile" style="height:120px">
            <img src="@/assets/images/footer/ctfe_logo.png">
          </div>
          <div class="sponsors-item sponsors-item-mobile" style="height:120px">
            <img src="@/assets/images/footer/ctfe_sol.png">
          </div>
        </div>
      </setion>
      <setion class="sponsors-item sponsors-item-mobile">
        <aside class="sponsors-txt sponsors-txt-mobile">{{$t('sponsor.title6')}}</aside>
        <div class="sponsors-box">
          <div class="sponsors-item sponsors-item-mobile">
            <img src="@/assets/images/footer/acoo.png" style="height:150px; margin-top: -80px; margin-left: -20px;">
          </div>
        </div>
      </setion>
      <setion class="sponsors-item sponsors-item-mobile">
        <aside class="sponsors-txt sponsors-txt-mobile">{{$t('sponsor.title2')}}</aside>
        <div class="sponsors-box">
          <div class="sponsors-item sponsors-item-mobile">
            <img src="@/assets/images/footer/dh.png" style="height:90px;">
          </div>
          <div class="sponsors-item sponsors-item-mobile">
            <img src="@/assets/images/footer/ef.png">
          </div>
          <div class="sponsors-item">
            <img src="@/assets/images/footer/kos.webp" style="height:90px; margin-top:-15px;">
          </div>
          <div class="sponsors-item">
            <img src="@/assets/images/footer/hkca.png" style="height:90px;">
          </div>
          <div class="sponsors-item">
            <img src="@/assets/images/footer/hkgcc.png">
          </div>
          <div class="sponsors-item">
            <img src="@/assets/images/footer/hrm.webp" style="height:90px; margin-top:-5px;">
          </div>
          <div class="sponsors-item">
            <img src="@/assets/images/footer/HKSTP_English.png" style="height:90px; margin-top:-5px;">
          </div>
          <div class="sponsors-item">
            <img src="@/assets/images/footer/JobsDB.png" style="height:90px; margin-top:-5px;">
          </div>
          <div class="sponsors-item" style="height:60px">
            <img src="@/assets/images/footer/theDesk.png">
          </div>
        </div>
      </setion>
      <setion class="sponsors-item">
        <aside class="sponsors-txt sponsors-txt-mobile">{{$t('sponsor.title3')}}</aside>
        <div class="sponsors-box">
          <div class="sponsors-item" style="height:60px">
            <img src="@/assets/images/footer/meta.png">
          </div>
        </div>
      </setion>
      <setion class="sponsors-item">
        <aside class="sponsors-txt">{{$t('sponsor.title4')}}</aside>
        <div class="sponsors-box">
          <div class="sponsors-item" style="height:60px">
            <img src="@/assets/images/footer/linkedin.jpg">
          </div>
        </div>
      </setion>
      <setion class="sponsors-item">
        <aside class="sponsors-txt">{{$t('sponsor.title5')}}</aside>
        <div class="sponsors-box">
          <div class="sponsors-item">
            <img src="@/assets/images/footer/hoytv_red.png" style="height:60px">
          </div>
          <div class="sponsors-item">
            <img src="@/assets/images/footer/i_cable.png" style="height:60px">
          </div>
        </div>
      </setion>
    </div>

  </div> -->
   <div class="show-img">
    <img class="show" :src="require(language === 'en' ? '@/assets/images/footer/Sponsor page_Eng-01.jpg' : '@/assets/images/footer/Sponsor page_Chi-01.jpg')" alt="" />
    </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      language:window.localStorage.getItem('locale')||"cn",
    }

  }

}
</script>

<style scoped lang="scss">
  .show-img{
    width : 80%;
    .show{
      width:100%; 
    }
  }
  .sponsors{
    &-container{
      margin: 50px auto;
      max-width: 100%;
      padding: 20px;
      .tips{
        text-align: right;
      }
      .sponsors-item{
        display: flex;
        flex-direction: row;
        margin-top: 50px;
        
        .sponsors-txt{
          width: 40%;
          color: #707070;
          font-size: 1.8em;
          font-family: "Lato", sans-serif;
          padding-left: 25px;
          text-align: start;
        }
        .advocacy{
          font-size: 3em;
        }

        .sponsors-box{
          width: 60%;
          display: flex;
          flex-wrap: wrap;
          .sponsors-item{
            padding: 20px;
            height: 100px;
            margin: 10px;
            width: fit-content;
            img{
              width: 100%;
              object-fit: contain;
              margin-top: -18px;
            }
          }
        }
        .sponsors-box-large{
          .sponsors-item{
            height: 160px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 520px) {

    .sponsors{
      &-container{
        padding: 0;
      }
    }
    .sponsors-mobile{
      padding:10px;
    }
    .sponsors-item-mobile{
      display:flex;
      flex-direction: row;
    }
    // .advocacy-mobile{
    //   font-size: 14px;
    // }
    .sponsors-txt-mobile{
      display: flex;
      width: 10%;
      color: green;
      margin-bottom: 1em;
      font-size: 14px;
      font-family: "Lato", sans-serif;
    } 
    
    .sponsors-box{
          width: 60%;
          display: flex;
          flex-wrap: wrap;
          .sponsors-item{
            padding: 20px;
            height: 100px;
            margin: 10px;
            width: fit-content;
            img{
              width: 100%;
              object-fit: contain;
              margin-top: -58px;
            }
          }
        }
  }
</style>